(function ($) {

    const MegaFilter = {
        init : function () {
            $('.mfilter-heading').on('click', function () {
                $(this).toggleClass('active');
                $(this).parent().find('.mfilter-content-opts').slideToggle();
            });
            $('.mfilter-col-input').on('click', function () {
                $(this).parent().find('input').trigger('click')
            });

            $('#input-sort').select2({
                minimumResultsForSearch: -1,
                dropdownParent: $('#input-sort').parent().get(0)
            });

            $('#input-limit').select2({
                minimumResultsForSearch: -1,
                dropdownParent: $('#input-limit').parent().get(0)
            });

            setTimeout(function () {
                if($('.mfilter-selected-filters-cnt a').length === 0) {
                    $('.mfilter-content').slideUp(0);
                } else {
                    $('.filterToggle').addClass('active');
                }
            }, 10);

            $('.filterToggle').on('click', function () {
                $(this).toggleClass('active');
                $('body').toggleClass('mobileNoScroll');
                $('.mfilter-content').slideToggle();
            });

            $('.mobile-filter-toggle').on('click', function () {
                $(this).toggleClass('active');
                $('.productToolbar').slideToggle();
                $('.mfilter-content').slideToggle();
            });

            jQuery(document).ajaxComplete(function () {
                var hiddenTotal = $('.hidden-total');
                if (hiddenTotal.length) {
                    $('.productToolbar__totalProducts span').text(hiddenTotal.text());
                }
            });

        }
    };

    $(document).ready(function () {
        MegaFilter.init();
    });

})(jQuery);
