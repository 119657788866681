(function ($) {

    /**
     * Add class to body with touch info
     */
    var touchClass = function() {
        function hasTouch() {
            return 'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
        }
        if (hasTouch()) {
            $('body').addClass('device--touch');
        } else {
            $('body').addClass('device--notouch');
        }
    };

    /**
     * Remove empty paragraphs
     */
    var removeEmptyParagraphs = function() {
        $('p').each(function () {
            var $this = $(this);
            if ($this.html().replace(/\s|&nbsp;/g, '').length === 0)
                $this.remove();
        });
    };

    /**
     * Replace non found images with placeholder images
     */
    var devImages = function() {
        if (window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('development') > -1) {
            $('').each(function (index, el) {
                var project = $(this);
                var img = new Image();
                img.src = el.style.backgroundImage.replace('url(', '').replace(')', '').replace(/"/gi, "");

                $(img).error(function () {
                    project.css('background-image', 'url(http://placehold.it/1000x400?text=Afbeelding+niet+beschikbaar)');
                });
            });

            $('img').error(function () {
                $(this).attr('src', 'http://placehold.it/625x400?text=Afbeelding+niet+beschikbaar');
            });
        }
    };

    var searchSwitch = function  () {
        $('.searchMobileSwitch').on('click', function (e) {
            e.preventDefault();
            $(this).toggleClass('active');
            $('.searchBar').toggleClass('active');
        });

        if($('.is-frontpage').length) {
            $('.searchBar').toggleClass('active').addClass('front');
            $('.searchMobileSwitch').remove();
            $('body').addClass('is-front');
        }
    };

    // DOM Ready
    $(function() {
        touchClass();
        removeEmptyParagraphs();
        devImages();
        searchSwitch();

        $('a.colorbox').colorbox({
            fixed:true
        });
    });

})(jQuery);
