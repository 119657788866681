$.fancybox.defaults.lang = "nl";
$.fancybox.defaults.i18n.nl = {
    CLOSE: "Sluiten",
    NEXT: "Volgende",
    PREV: "Vorige",
    ERROR: "De content kan niet geladen worden. <br/> Probeer later opnieuw.",
    PLAY_START: "Start slideshow",
    PLAY_STOP: "Pauzeer slideshow",
    FULL_SCREEN: "Volledig scherm",
    THUMBS: "Thumbnails",
    DOWNLOAD: "Download",
    SHARE: "Delen",
    ZOOM: "Zoom"
};