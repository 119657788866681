(function ($) {

    /**
     * DatePicker
     */
    var datePicker = function () {
        var datePicker = $('input.datepicker');
        if ($.datepicker === undefined) {
            return false;
        }
        datePicker.datepicker({
            minDate: +1,
            // maxDate: +30,
            // firstDay: 1,
            changeMonth: false,
            changeYear: false,
            // beforeShowDay: function (date) {
            //     var day = date.getDay();
            //     if (day === 2 || day === 4) {
            //         return [true];
            //     }
            //     return [false];
            // },
            clearText: 'Effacer', clearStatus: '',
            closeText: 'sluiten', closeStatus: 'Onveranderd sluiten ',
            prevText: '<vorige', prevStatus: 'Zie de vorige maand',
            nextText: 'volgende>', nextStatus: 'Zie de volgende maand',
            currentText: 'Huidige', currentStatus: 'Bekijk de huidige maand',
            monthNames: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
            monthNamesShort: ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
            monthStatus: 'Bekijk een andere maand', yearStatus: 'Bekijk nog een jaar',
            weekHeader: 'Sm', weekStatus: '',
            dayNames: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
            dayNamesShort: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
            dayNamesMin: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
            dayStatus: 'Gebruik DD als de eerste dag van de week', dateStatus: 'Kies DD, MM d',
            initStatus: 'Kies een datum', isRTL: false
        }).attr("readonly", "readonly");
    };


    // DOM Ready
    $(function () {
        setTimeout(function () {
            datePicker();
        }, 100);
    });

})(jQuery); // Fully reference jQuery after this point.