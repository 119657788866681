(function ($) {

    /**
     * CategoryMenu Class
     */
    const CategoryMenu = {

        wrapper : null,

        menu : null,

        hideTimer : null,

        timeout: 0,

        scroller: null,

        scrollerElement : null,

        menuTrigger : null,

        breadcrumb : null,

        activeMenuItemSelector : "li.categoryMenu__item--active",

        /**
         * Init
         */
        init : function () {
            this.menu = $('.categoryMenu');
            this.wrapper = $('.categoryMenu__wrapper');
            this.menuTrigger = $('a.menuTrigger');
            this.breadCrumb = this.wrapper.find('.categoryMenu__breadcrumb');

            // Add Event Listeners
            this.menu.find('ul > li > a').on('click', function (e) {
                if (!$(e.target).parent().hasClass('categoryMenu__item--back')) {
                    this.onSubmenuTriggerClick(e.target, e);
                }
            }.bind(this));

            $('.categoryMenu__backTrigger').on('click', this.onBackTriggerClick.bind(this));
            $('.categoryMenu__backArrow').on('click', this.onBackTriggerClick.bind(this));

            this.menuTrigger.on('click', this.onMenuTriggerClick.bind(this));

            $('.menu__niv2').hide();
            this.updateBreadcrumb();

            $(document).keyup(function(e) {
                if (e.keyCode === 27) {
                    this.menu.removeClass('open');
                    this.wrapper.removeClass('open');
                    this.menuTrigger.removeClass('open');
                }
            }.bind(this));
        },

        /**
         * Open / Close menu
         *
         * @param e
         */
        onMenuTriggerClick : function (e) {
            e.preventDefault();
            this.menu.toggleClass('open');
            this.wrapper.toggleClass('open');
            this.menuTrigger.toggleClass('open');

            if (this.menu.hasClass('open')) {
                this.menu.niceScroll({
                    autohidemode: "false",
                    horizrailenabled: false,
                    railpadding: { top: 0, right: 0, left: 0, bottom: 0 },
                    cursorcolor: "#dcdcdc",
                    cursorwidth: "19px",
                    cursorborder: 'none',
                    cursorborderradius: 0,
                    background: "white",
                    nativeparentscrolling: false
                });

                this.scroller = this.menu.getNiceScroll()[0];
                this.scrollerElement = $('.nicescroll-rails');
            }
        },

        /**
         * Handle click on menu item arrow
         */
        onSubmenuTriggerClick : function (target, e) {
            this.menu.find('ul.active').removeClass('active');
            var submenu = $(target).parent().find('> ul').addClass('active');
            submenu.find('> li').height('auto');

            if(submenu.length) {
                var niv = $(target).parents('ul').length;
                this.menu.attr('data-niv', niv);
                if (typeof e != "undefined") {
                    e.preventDefault();
                }
                this.menu.getNiceScroll().show();
                this.menu.addClass('categoryMenu--submenuActive');
                // this.menu.find('ul ul').hide();
                this.setHeightToElement(submenu);
                this.scrollerElement.addClass('hidden');
                submenu.show();

                setTimeout(function() {
                    var searchString = '';
                    for (var i = 0; i < niv; i++) {
                        searchString += '> ul > li ';
                        this.menu.find(searchString).height(1);
                    }
                    this.updateBreadcrumb();
                }.bind(this), (this.timeout ? this.timeout + 25 : 0));
            }
        },

        updateBreadcrumb : function () {
            const activeUl = this.menu.find('.menu__niv1 ul.active').first();
            const breadCrumbUl = this.breadCrumb.find('ul').empty();

            if (activeUl.length) {
                var root = this;

                // Prepend root item title
                $('<li></li>').append('<a></a>').text('Alle producten').appendTo(breadCrumbUl).on('click', function () {
                    for (i=0; i < (breadCrumbUl.find('li').length - 1); i++) {
                        setTimeout(function () {
                            root.onBackTriggerClick();
                        }, (100 * i));
                    }
                }.bind(root));

                // Prepend item title of parents
                activeUl.parents('ul').not('.menu__niv1').each(function () {
                    var backClicks = 0;
                    backClicks++;
                    const title = $(this).find('li.categoryMenu__item--title').first().find('.categoryMenu__categoryTitle').text();
                    $('<li></li>').text(title).appendTo(breadCrumbUl).on('click', function () {
                        for (i=0; i<backClicks; i++) {
                            root.onBackTriggerClick();
                        }
                    }.bind(root));
                });

                // Prepend current item title
                $('<li></li>').text(activeUl.find('li.categoryMenu__item--title').first().find('.categoryMenu__categoryTitle').text()).appendTo(breadCrumbUl);

                $('.categoryMenu__backArrow').removeClass('disabled');
            } else {
                $('<li></li>').append('<a></a>').text('Alle producten').appendTo(breadCrumbUl);
                $('.categoryMenu__backArrow').addClass('disabled');
            }

        },

        /**
         * Handle click on back trigger
         */
        onBackTriggerClick : function () {

            const niv = this.menu.attr('data-niv');
            if (niv > 0) {
                const currentElement = this.menu.find('ul.active');
                const previousElement = currentElement.parent().parent();
                this.scrollerElement.addClass('hidden');
                this.menu.attr('data-niv', niv - 1);
                previousElement.find('> li').height('auto');
                this.setHeightToElement(previousElement);

                setTimeout(function () {
                    this.menu.find('ul.active').removeClass('active');
                    previousElement.addClass('active');
                    currentElement.hide();
                    this.updateBreadcrumb();
                }.bind(this), 10);
            }
        },

        /**
         * @param element
         */
        setHeightToElement : function (element) {
            setTimeout(function() {
                this.menu.height(element.height() + 2);
                this.menu.getNiceScroll().hide();
            }.bind(this), 1);

            setTimeout(function() {
                this.scroller.resize();
                this.scroller.doScrollTop(0, 0);
                this.scrollerElement.removeClass('hidden');
                this.menu.toggleClass('categoryMenu--withScrollbar', this.scrollerElement.is(':visible'));
            }.bind(this), (this.timeout + 200));
        }
    };

    $(document).ready(function () {
        CategoryMenu.init();
    });

})(jQuery);
