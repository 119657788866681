(function ($) {

    function select2() {
        $('.select2-wrapper').each(function () {
            var placeholder = $(this).data('placeholder');
            $(this).find('select').select2({
                minimumResultsForSearch: 20,
                placeholder: placeholder,
                dropdownParent: '#' + $(this).attr('id'),
                templateSelection: function (state) {
                    return state.text.trim();
                },
                templateResult: function (state) {
                    return state.text;
                },

            });
        });
    };

    $(document).ready(function(){
        select2();
    });

})(jQuery);