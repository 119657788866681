(function($){
    $(document).ready(function() {
        var ezbase = $('#productDescription .EZ-base');

        if (ezbase.length) {
            var panel = $('#accordion > .panel').first().clone(false, false).insertAfter('#accordion > .panel:first-child');

            // Remove original body
            panel.find('.panel-body').empty();

            // Hide product details in product description
            ezbase.hide();

            // Fix accordion handler
            panel.find('.accordion-toggle').attr('href', '#ezbase').text('Specificaties')
            panel.find('.panel-collapse').attr('id', 'ezbase');

            ezbase.find('tr').each(function () {
                var template = '<div class="panel-attribute">' +
                    '<div class="panel-attributeLabel">' + $(this).find('td').first().text() + '</div>' +
                    '<div class="panel-attributeValue">' + $(this).find('td').last().text() + '</div>' +
                    '</div>';
                panel.find('.panel-body').append(template);
            });
        }
    });
})(jQuery);