(function($) {

    /**
     * Main Slider
     */
    var blockImages = function() {
        $('.blockImages').each(function () {
            var target = $(this);
            var arrowClass = 'slideArrow';
            var sliderSwiper = new Swiper('.blockImages', {
                slidesPerView: 3,
                loop: false,
                watchOverflow: true,
                navigation: {
                    nextEl: target.find('.' + arrowClass + '--next').get(0),
                    prevEl: target.find('.' + arrowClass + '--prev').get(0),
                    disabledClass: arrowClass + '--disabled',
                    hiddenClass: arrowClass + '--hidden'
                },
                breakpoints: {
                    1023: {
                        slidesPerView: 1,
                    }
                }
            });
        });
    };


    // DOM Ready
    $(function() {
        blockImages();
    });

})(jQuery); // Fully reference jQuery after this point.